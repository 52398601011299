const bulkIngestionType = [
  {
    id: 0,
    value: 'Epub'
  },
  {
    id: 1,
    value: 'Spdf'
  }
];

const BulkIngestionConfig = {
  bulkIngestionType
};
module.exports = BulkIngestionConfig;

import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Framework, { shapes, LoadingHandler } from '@greenville/framework';
import { toJS } from 'mobx';
import { FormattedMessage } from 'react-intl';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
  Typography,
  Grid,
  Paper,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import * as constants from '../../../../common/constants';
import TableComponent from '../../../../common/components/Table';
import ColumnConfig from '../../../../common/config/ColumnConfig';
import EffectivenessData from '../../models/StudyToolEffectiveness';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import EffectivenessDialogComponent from './EffectivenessDialogComponent';
import Loader from '../../../../common/Loader';
import UsersPermissions from '../../../users/models/UsersPermissions';

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      yearSelected: {
        color: constants.PEARSON_PRIMARY_COLOR
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      daySelected: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: constants.PEARSON_PRIMARY_COLOR
        }
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    }
  }
});

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = (theme) => ({
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  datePicker: {
    marginTop: 'inherit'
  },
  marginBox: {
    border: '1px solid #322c2c2e',
    width: '52%',
    padding: '2px 15px 2px 0px',
    borderRadius: '4px',
    color: '#fff',
    height: '41px'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  buttonStyle: {
    width: '150px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -20
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: '14px !important'
  },
  tableBookWidth: {
    width: '150px'
  },
  tableSummaryWidth: {
    width: '150px'
  },
  centerAlign: {
    textAlign: 'center'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  }
});

/* eslint-disable */
const EffectivenessMainComponent = ({
  effectivenessData,
  EffectivenessMetaDataStatus,
  EffectivenessDataStatus,
  classes,
  userPermissions,
  language
}) => {
  const [effectivenessDataValue, setEffectivenessData] = useState([]);
  const [searchFields, setSearchFields] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [effectivenessRecords, setEffectivenessRecords] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dataValue, setDataValue] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const permissionsList = toJS(userPermissions.permissionList.permissions);

  useEffect(() => {
    effectivenessData.fetchMetadata();
    Framework.getEventManager().on(constants.SET_STUDYTOOL_EFFECTIVENESS_METADATA, () => {
      setEffectivenessData(toJS(effectivenessData.data));
    });
  }, []);

  const handleInitiate = () => {
    effectivenessData.initiate();
  };

  const format = (records) =>
    records.map((record) => ({
      input: record.input,
      output: record.output.toString().replace(/\\r\\n/g, '<br >'),
      prompt: record.prompt,
      requestId: record.requestId,
      feedbacks: record.feedbacks
    }));

  const handleShowResults = (data) => {
    setEffectivenessRecords(format(data.records));
    setShowResults(true);
  };

  const onRowClick = (row, index) => {
    setIsDialogOpen(true);
    setDataValue(effectivenessRecords[index]);
    setModalIndex(index);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
    setShowResults(false);
  };

  const handleModalChange = (value) => {
    if (value === 'prev' && modalIndex > 0) {
      setDataValue(effectivenessRecords[modalIndex - 1]);
      setModalIndex(modalIndex - 1);
    } else {
      setDataValue(effectivenessRecords[modalIndex + 1]);
      setModalIndex(modalIndex + 1);
    }
  };

  const handleDateChange = (date, name) => {
    const fieldChanged = {};
    fieldChanged[name] = moment(date).format(constants.YYYY_MM_DD_FORMAT);

    setSearchFields({ ...searchFields, ...fieldChanged });
  };

  const handleSearch = (date) => {
    date = moment(date).format(constants.DD_MM_YYYY_FORMAT);
    const payload = {
      dateExecuted: date
    };

    effectivenessData.fetch(payload);
    Framework.getEventManager().on(constants.SET_STUDYTOOL_EFFECTIVENESS_DATA, () => {
      setEffectivenessData(toJS(effectivenessData.data));
    });
  };

  // const sorting = (value) => {
  //   const { setOrder } = this.state;
  //   if (setOrder === 'ASC') {
  //     this.handleAscending('asc', value);
  //     this.setState({
  //       setOrder: 'DSC',
  //       setDirectionValue: value
  //     });
  //   }
  //   if (setOrder === 'DSC') {
  //     this.handleAscending('des', value);
  //     this.setState({
  //       setOrder: 'ASC',
  //       setDirectionValue: value
  //     });
  //   }
  // };

  return (
    <>
      <Paper>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ p: 3 }}>
            <Typography variant="h5" align="center" gutterBottom spacing={9}>
              {constants.CHAT_CRITERIA_TEXT}
            </Typography>
          </Box>
          <Grid container direction="row">
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', p: '0px 33px' }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <ThemeProvider theme={materialTheme}>
                    <KeyboardDatePicker
                      className={classes.datePicker}
                      format={constants.MM_DD_YYYY_FORMAT}
                      autoOk
                      size="small"
                      variant="inline"
                      style={{ minWidth: '32%' }}
                      inputVariant="outlined"
                      disableFuture
                      value={searchFields.lastExecutedDate || null}
                      label="Last Executed Date"
                      onChange={(date) => handleDateChange(date, 'lastExecutedDate')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
                <ThemeProvider theme={themes}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => handleSearch(searchFields.lastExecutedDate)}
                    className={classes.buttonStyle}
                  >
                    {constants.CHAT_SEARCH}
                  </Button>
                  <TooltipCustom
                    title={
                      !permissionsList.includes('mcq_can_edit') ? (
                        <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                      ) : (
                        <FormattedMessage {...language.getText('effectiveness.INITIATE_NEW')} />
                      )
                    }
                  >
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={handleInitiate}
                      className={classes.buttonStyle}
                      disabled={!permissionsList.includes('mcq_can_edit')}
                    >
                      {constants.INITIATE_NEW}
                    </Button>
                  </TooltipCustom>
                </ThemeProvider>
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <br />
        </Box>
        <br />
      </Paper>
      <br />
      <LoadingHandler
        loading={EffectivenessMetaDataStatus.isPending || EffectivenessDataStatus.isPending}
        loadingContent={<Loader />}
        content={
          !showResults && (
            <TableContainer component={Paper} className={classes.root}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.tableBookWidth}>
                      <Typography className={classes.columnStyle} variant="h6">
                        {constants.DATE_TIME_GMT}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.tableBookWidth}>
                      <Typography className={classes.columnStyle} variant="h6">
                        {constants.S3_PATH}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.tableBookWidth}>
                      <Typography className={classes.columnStyle} variant="h6">
                        {constants.EXECUTED_BY}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.tableBookWidth}>
                      <Typography className={classes.columnStyle} variant="h6">
                        {constants.SHOW_RESULTS}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {effectivenessDataValue &&
                    Array.isArray(effectivenessDataValue) &&
                    effectivenessDataValue.length > 0 &&
                    effectivenessDataValue.map((data) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell>
                              <Typography className={classes.tableText}>{data.metadata.dateExecuted}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.tableText}>{data.metadata.s3FilePath}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.tableText}>{data.metadata.executedBy}</Typography>
                            </TableCell>
                            <TableCell className={classes.centerAlign}>
                              <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                onClick={() => handleShowResults(data)}
                                disabled={data.disable}
                                className={classes.buttonStyle}
                              >
                                {constants.SHOW_RESULTS}
                              </Button>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }
      />
      {showResults && (
        <TableComponent
          columns={ColumnConfig.EffectivenessDataGridColumn}
          data={effectivenessRecords}
          onColumnClick={() => {}}
          order=""
          onRowClick={onRowClick}
          selectedValue=""
          directionValue="ASC"
          userEmailDetails=""
          onMouseEnter={() => {}}
          isFromChannelsTrends
        />
      )}
      {isDialogOpen && (
        <EffectivenessDialogComponent
          dataValue={dataValue}
          openChat={isDialogOpen}
          dataLength={effectivenessRecords.length}
          handleClose={handleClose}
          handleModalChange={handleModalChange}
          modalIndex={modalIndex}
        />
      )}
    </>
  );
};

EffectivenessMainComponent.propTypes = {
  effectivenessData: shapes.modelOf(EffectivenessData).isRequired,
  classes: PropTypes.object.isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired,
  language: shapes.language.isRequired
};

export default withStyles(styles)(
  inject(
    'effectivenessData',
    'EffectivenessDataStatus',
    'EffectivenessMetaDataStatus',
    'userPermissions',
    'language'
  )(observer(EffectivenessMainComponent))
);

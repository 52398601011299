/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Typography, Button, Grid, Paper, Box, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';
import BulkIngestion from '../models/BulkIngestion';
import Upload from '../../contentManager/models/Upload';
import BulkIngestionConfig from '../../../common/config/BulkIngestionConfig';
import ChooseFile from '../../../common/components/ChooseFile';

const styles = (theme) => ({
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -40
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  }
});
@inject('bulkIngestion', 'upload')
@observer
class BulkIngestionComp extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    handleOpenUploadContent: PropTypes.func.isRequired,
    bulkIngestion: shapes.modelOf(BulkIngestion).isRequired,
    upload: shapes.modelOf(Upload).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      uploadContentPath: '',
      titleType: '',
      titleTypeId: null
    };
    this.createContentPath = this.createContentPath.bind(this);
  }

  createContentPath = (titleType) => {
    const uploadContentPathUrl = `${constants.BULK_INGESTION_FOLDER}${constants.SEPARATOR}${titleType}${
      constants.SEPARATOR
    }${utils.uuid()}${constants.SEPARATOR}`;
    this.setState({ uploadContentPath: uploadContentPathUrl });
  };

  handleClick = () => {
    const { bulkIngestion, upload } = this.props;
    const { uploadContentPath, titleType } = this.state;
    const result = upload.bucketFolders;
    const sheetName = result[0];
    const finalPath = `${uploadContentPath}${sheetName}`;
    bulkIngestion.fetchPath(finalPath, titleType);
  };

  handleSelectionChange = (event, value) => {
    const { titleType } = this.state;
    if (value === 'titleType') {
      this.setState({
        titleType: BulkIngestionConfig.bulkIngestionType
          .find((data) => data.id === event.target.value)
          .value.toLowerCase(),
        titleTypeId: event.target.value,
        uploadContentPath: this.createContentPath(titleType)
      });
    }
  };

  render() {
    const { classes, language } = this.props;
    const { uploadContentPath, titleTypeId, titleType } = this.state;
    if (!uploadContentPath) {
      this.createContentPath(titleType);
    }
    return (
      <>
        <div className={classes.container}>
          <Paper className={classes.paper} elevation={3}>
            <Grid container direction="column" alignItems="center" justify="space-between">
              <Typography variant="h3" align="center" gutterBottom spacing={3} className={classes.title}>
                <FormattedMessage {...language.getText('bulkutility.TITLE_BULKINGESTION')} />
              </Typography>
              <Box mb={0.5} mr={1} className={classes.boxWidth}>
                <FormControl fullWidth className={classes.filterControl}>
                  <InputLabel>
                    <FormattedMessage {...language.getText('contentmanager.TITLE_TYPE')} />
                  </InputLabel>

                  <Select
                    className={classes.labelWidht}
                    name="titleType"
                    defaultValue="0"
                    value={titleTypeId}
                    onChange={(event) => this.handleSelectionChange(event, 'titleType')}
                  >
                    {BulkIngestionConfig.bulkIngestionType.map((filter) => (
                      <MenuItem value={filter.id}>{filter.value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <ChooseFile
                message={{ ...language.getText('bulkutility.UPLOAD_EXCEL') }.defaultMessage}
                disabled={!titleType}
                uploadPath={uploadContentPath}
              />
              <Grid item className={classes.button}>
                <Button
                  disabled={!titleType}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={() => this.handleClick()}
                >
                  <FormattedMessage {...language.getText('Start Processing')} />
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(BulkIngestionComp)));

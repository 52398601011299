import { LoadingHandler } from '@greenville/framework';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactWordcloud from 'react-wordcloud';
import Loader from '../../../../../common/Loader';
import * as constants from '../../../../../common/constants';
import env from '../../../../../common/env';
import utils from '../../../../../common/utils';
import AutocompleteComponent from '../../../common/components/Autocomplete';

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = (theme) => ({
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  datePicker: {
    marginTop: 'inherit'
  },
  marginBox: {
    border: '1px solid #322c2c2e',
    width: '52%',
    padding: '2px 15px 2px 0px',
    borderRadius: '4px',
    color: '#fff',
    height: '41px'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  buttonStyle: {
    width: '120px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -20
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  }
});

const AITopicCharts = (props) => {
  const { classes } = props;
  const [chartsData, setChartsData] = useState({});
  const [userToken, setUserToken] = useState('');
  const [apiLoading, setApiLoading] = useState(false);
  const [bookList, setBookList] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const getChartsData = async () => {
    setApiLoading(true);
    const headers = {
      Authorization: `Bearer ${userToken}`
    };
    const response = await axios.get(`${env.EVERGREEN_API_BASE_URL}${constants.GET_AI_TOPIC_CHARTS_URL}`, {
      headers
    });
    if (response && response.data && response.data.data) {
      const resp = response.data.data;
      const counts = {};
      /* eslint-disable */
      resp.forEach((item) => {
        const name = item.clusterName;
        if (counts[name]) {
          counts[name]++;
        } else {
          counts[name] = 1;
        }
      });
      /* eslint-disable */
      const output = Object.keys(counts).map((key) => ({
        text: key,
        value: counts[key]
      }));
      setChartsData(output);
      setApiLoading(false);
    }
  };

  const getBooksData = async () => {
    const headers = {
      Authorization: `Bearer ${userToken}`
    };
    const booksdata = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.GET_BOOK_DATA_URL}`, {}, { headers });
    if (booksdata && booksdata.data && booksdata.data.data) {
      setBookList(booksdata.data.data);
    }
  };

  useEffect(() => {
    const token = utils.getToken();
    setUserToken(token);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(userToken)) {
      getBooksData();
    }
  }, [userToken]);

  const handleSearch = () => {
    getChartsData();
  };

  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const options = {
    colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
    enableTooltip: true,
    deterministic: false,
    fontFamily: 'impact',
    fontSizes: [12, 50],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 1,
    rotations: 3,
    rotationAngles: [0, 90],
    scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 1000
  };

  return (
    <>
      <LoadingHandler
        loading={apiLoading}
        loadingContent={<Loader />}
        content={
          <>
            <Paper>
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ p: 3 }}>
                  <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    spacing={9}
                    className={classes.title}
                    style={{ p: 2, mt: 2 }}
                  >
                    Please enter search criteria to fetch the Trending Topics
                  </Typography>
                </Box>
                <Grid container direction="row">
                  <Grid item xs={2} />
                  <Grid item xs={8}>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', p: '0px 33px' }}>
                      <FormControl sx={{ minWidth: '39%', maxWidth: '90%', mr: 5 }} size="small">
                        <AutocompleteComponent
                          options={bookList}
                          onAutoCompleteChange={() => {}}
                          onInputChange={onInputChange}
                          stateVal={inputValue}
                          errorVal=""
                          name="bookId"
                          label="Book Id"
                        />
                      </FormControl>
                      <Box className={classes.marginBox} sx={{ '& button': { m: 31, p: 12 } }}>
                        <Stack direction="row" spacing={1}>
                          <Button size="small" variant="outlined" style={{ color: '#0d0c0c8c' }} onClick={() => {}}>
                            1d
                          </Button>
                          <Button size="small" style={{ color: '#0d0c0c8c' }} variant="text" onClick={() => {}}>
                            3d
                          </Button>
                          <Button size="small" style={{ color: '#0d0c0c8c' }} variant="text" onClick={() => {}}>
                            1w
                          </Button>
                          <Button size="small" variant="text" style={{ color: '#0d0c0c8c' }} onClick={() => {}}>
                            custom
                            <CalendarMonthIcon />
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
                <br />
              </Box>
              <br />
              <Box>
                <Grid container justify="space-evenly" className={classes.buttonGroupStyle}>
                  <ThemeProvider theme={themes}>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={handleSearch}
                      className={classes.buttonStyle}
                    >
                      {constants.CHAT_SEARCH}
                    </Button>
                    <Button variant="contained" size="small" color="primary" className={classes.buttonStyle}>
                      {constants.CHAT_RESET}
                    </Button>
                  </ThemeProvider>
                </Grid>
                <br />
              </Box>
            </Paper>
            <br />
            {!_.isEmpty(chartsData) && (
              <Paper style={{ padding: '10px' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <div style={{ width: '80%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <h2>Trending Topics</h2>
                    <div style={{ height: '100%', width: '100%' }}>
                      <ReactWordcloud options={options} words={chartsData} />
                    </div>
                  </div>
                </div>
              </Paper>
            )}
          </>
        }
      />
    </>
  );
};

AITopicCharts.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AITopicCharts);

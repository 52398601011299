/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import BulkIngestionService from './services/BulkIngestionService';
import BulkActionService from './services/BulkActionService';
import BulkIngestion from './models/BulkIngestion';
import BulkAction from './models/BulkAction';
import BulkUtilityContainer from './BulkUtilityContainer';

export default {
  path: ['/bulkutility/ingestion', '/bulkutility/action'],
  exact: true,
  services: [new BulkIngestionService(), new BulkActionService()],
  stores: [
    {
      name: 'bulkIngestion',
      spec: BulkIngestion,
      saveLocally: false,
      initialState: {
        bulkIngestion: {}
      }
    },
    {
      name: 'bulkAction',
      spec: BulkAction,
      saveLocally: false,
      initialState: {
        bulkAction: {}
      }
    }
  ],
  render: (props) => (
    <BulkUtilityContainer {...props} /> // eslint-disable-line react/prop-types
  )
};

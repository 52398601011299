import { LoadingHandler } from '@greenville/framework';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Loader from '../../../../common/Loader';
import TableComponent from '../../../../common/components/Table';
import ColumnConfig from '../../../../common/config/ColumnConfig';
import * as constants from '../../../../common/constants';
import env from '../../../../common/env';
import utils from '../../../../common/utils';
import AutocompleteComponent from '../../common/components/Autocomplete';
import AIChartsDialog from './AIChartsDialog';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = (theme) => ({
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  datePicker: {
    marginTop: 'inherit'
  },
  marginBox: {
    border: '1px solid #322c2c2e',
    width: '52%',
    padding: '2px 15px 2px 0px',
    borderRadius: '4px',
    color: '#fff',
    height: '41px'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  buttonStyle: {
    width: '120px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -20
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  }
});

const AIChartsComponent = (props) => {
  const { classes } = props;
  const [chartsData, setChartsData] = useState({});
  const [userToken, setUserToken] = useState('');
  const [tableData, setTableData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalIndex, setModalIndex] = useState(0);
  const [bookList, setBookList] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // Process the data to get counts
  const processData = (param) => {
    const result = {};
    /* eslint-disable */
    param.forEach((item) => {
      if (!result[item.type]) {
        result[item.type] = { LIKE: 0, DISLIKE: 0, details: [] };
      }
      result[item.type][item.feedback]++;
      result[item.type].details.push(item);
    });
    /* eslint-disable */
    return result;
  };

  const getChartsData = async () => {
    setApiLoading(true);
    const headers = {
      Authorization: `Bearer ${userToken}`
    };
    const response = await axios.get(`${env.EVERGREEN_API_BASE_URL}${constants.GET_AI_CHARTS_FEEDBACK_URL}`, {
      headers
    });
    if (response && response.data && response.data.data) {
      const resp = response.data.data;
      const processedData = processData(resp);
      const types = Object.keys(processedData);
      const likeCounts = types.map((type) => processedData[type].LIKE);
      const dislikeCounts = types.map((type) => processedData[type].DISLIKE);

      const chartResData = {
        labels: types,
        datasets: [
          {
            label: 'LIKE',
            data: likeCounts,
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
            barThickness: 20,
            stack: 'stack0' // Use a stack group to align bars
          },
          {
            label: 'DISLIKE',
            data: dislikeCounts,
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            barThickness: 20,
            stack: 'stack1' // Use a different stack group to align bars
          }
        ]
      };
      setChartsData(chartResData);
      setResponseData(processedData);
      setApiLoading(false);
    }
  };

  const getBooksData = async () => {
    const headers = {
      Authorization: `Bearer ${userToken}`
    };
    const booksdata = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.GET_BOOK_DATA_URL}`, {}, { headers });
    if (booksdata && booksdata.data && booksdata.data.data) {
      setBookList(booksdata.data.data);
    }
  };

  useEffect(() => {
    const token = utils.getToken();
    setUserToken(token);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(userToken)) {
      getBooksData();
    }
  }, [userToken]);

  const handleBarClick = (event, elements) => {
    if (elements.length > 0) {
      const chartElement = elements[0];
      const datasetIndex = chartElement.datasetIndex;
      const dataIndex = chartElement.index;
      const type = chartsData.labels[dataIndex];
      const feedback = chartsData.datasets[datasetIndex].label;
      const details = responseData[type].details.filter((item) => item.feedback === feedback);
      setTableData(details);
      setPage(0);
    }
  };

  const onRowClick = (val, index) => {
    setOpenDialog(true);
    setModalIndex(index);
    setSelectedValue(val);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setModalIndex(1);
    setSelectedValue({});
  };

  const handleModalChange = (value) => {
    if (value === 'prev' && modalIndex > 0) {
      setSelectedValue(tableData[modalIndex - 1]);
      setModalIndex(modalIndex - 1);
    } else {
      setSelectedValue(tableData[modalIndex + 1]);
      setModalIndex(modalIndex + 1);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setFromToValueForPagination = (from, to, count) => {
    return `${from}-${to} of ${count}`;
  };

  const handleSearch = () => {
    getChartsData();
  };

  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  return (
    <>
      <LoadingHandler
        loading={apiLoading}
        loadingContent={<Loader />}
        content={
          <>
            <Paper>
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ p: 3 }}>
                  <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    spacing={9}
                    className={classes.title}
                    style={{ p: 2, mt: 2 }}
                  >
                    Please enter search criteria to fetch the User Feedback details
                  </Typography>
                </Box>
                <Grid container direction="row">
                  <Grid item xs={2} />
                  <Grid item xs={8}>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', p: '0px 33px' }}>
                      <FormControl sx={{ minWidth: '39%', maxWidth: '90%', mr: 5 }} size="small">
                        <AutocompleteComponent
                          options={bookList}
                          onAutoCompleteChange={() => {}}
                          onInputChange={onInputChange}
                          stateVal={inputValue}
                          errorVal=""
                          name="bookId"
                          label="Book Id"
                        />
                      </FormControl>
                      <Box className={classes.marginBox} sx={{ '& button': { m: 31, p: 12 } }}>
                        <Stack direction="row" spacing={1}>
                          <Button size="small" variant="outlined" style={{ color: '#0d0c0c8c' }} onClick={() => {}}>
                            1d
                          </Button>
                          <Button size="small" style={{ color: '#0d0c0c8c' }} variant="text" onClick={() => {}}>
                            3d
                          </Button>
                          <Button size="small" style={{ color: '#0d0c0c8c' }} variant="text" onClick={() => {}}>
                            1w
                          </Button>
                          <Button size="small" variant="text" style={{ color: '#0d0c0c8c' }} onClick={() => {}}>
                            custom
                            <CalendarMonthIcon />
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
                <br />
              </Box>
              <br />
              <Box>
                <Grid container justify="space-evenly" className={classes.buttonGroupStyle}>
                  <ThemeProvider theme={themes}>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={handleSearch}
                      className={classes.buttonStyle}
                    >
                      {constants.CHAT_SEARCH}
                    </Button>
                    <Button variant="contained" size="small" color="primary" className={classes.buttonStyle}>
                      {constants.CHAT_RESET}
                    </Button>
                  </ThemeProvider>
                </Grid>
                <br />
              </Box>
            </Paper>
            <br />
            {!_.isEmpty(chartsData) && (
              <Paper style={{ padding: '10px' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <div style={{ width: '60%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <h2>User Feedback Chart</h2>
                    <Bar
                      data={chartsData}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: 'top'
                          },
                          tooltip: {
                            callbacks: {
                              label: (context) => {
                                const label = context.dataset.label || '';
                                const value = context.raw || 0;
                                return `${label}: ${value}`;
                              }
                            }
                          }
                        },
                        scales: {
                          x: {
                            stacked: false,
                            grid: {
                              display: false
                            },
                            ticks: {
                              autoSkip: false
                            }
                          },
                          y: {
                            stacked: true, // Stack bars on the y-axis if needed
                            beginAtZero: true
                          }
                        },
                        onClick: (event, elements) => handleBarClick(event, elements)
                      }}
                    />
                  </div>
                </div>
              </Paper>
            )}
            {!_.isEmpty(tableData) && tableData.length > 0 && (
              <Paper style={{ padding: '10px', marginTop: '30px' }}>
                <Box sx={{ width: '100%' }} style={{ marginTop: '30px' }}>
                  <TableComponent
                    columns={ColumnConfig.AIStudyChartsFeedbackColumn}
                    data={tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    onColumnClick={() => {}}
                    order=""
                    onRowClick={onRowClick}
                    directionValue="ASC"
                    userEmailDetails=""
                    onMouseEnter={() => {}}
                  />
                  <TablePagination
                    component="div"
                    count={tableData.length}
                    labelDisplayedRows={({ from, to, count }) =>
                      useMemo(() => setFromToValueForPagination(from, to, count), [from, to, count])
                    }
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  />
                </Box>
              </Paper>
            )}
          </>
        }
      />
      {openDialog && (
        <AIChartsDialog
          openChat={openDialog}
          dataLength={tableData.length}
          handleClose={handleClose}
          dataValue={selectedValue}
          modalIndex={modalIndex}
          handleModalChange={handleModalChange}
          isFeedback
        />
      )}
    </>
  );
};

AIChartsComponent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AIChartsComponent);

import Framework, { shapes } from '@greenville/framework';
import { Box, Button, withStyles } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TableComponent from '../../../../../common/components/Table';
import * as constants from '../../../../../common/constants';
import utils from '../../../../../common/utils';
import ChannelsModel from '../../../models/ChannelsModel';
import ChatgptUtilityIESUser from '../../../models/ChatgptIESUser';
import ChatgptUtilityMapping from '../../../models/ChatgptMapping';
import SubTypeResponse from '../../../models/SubTypeModel';
import DialogComponent from '../../../../../common/components/Dialog';
import ColumnConfig from '../../../../../common/config/ColumnConfig';
import ExportToCSV from '../../../common/components/ExportToCSV';

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = () => ({
  buttonStyle: {
    cursor: 'pointer',
    width: '170px',
    margin: '10px',
    fontSize: '14px',
    background: '#005d83 !important'
  }
});

/* eslint-disable no-shadow */
function ChannelsDataGrid(props) {
  const {
    channelsUtilityData,
    handleAscending,
    ChatgptUtilityIESUserData,
    classes,
    chatgptMappingUtility,
    isNextPageKey,
    handleSearch,
    channelsSubType,
    channelsModelData,
    modelStatus,
    setUserId,
    userIdChecked
  } = props;
  const [subTypeData, setSubTypeData] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [order, SetOrder] = useState('ASC');
  const [directionValue, setDirectionValue] = useState('');
  const [userEmailDetails, setUserEmailDetails] = useState({});
  const [selectedValue, setSelectedValue] = useState('');
  const [openChat, openChatDialog] = useState(false);
  const [dataValue, setDataValue] = useState({});
  const [userTocDetails, setUserTocDetails] = useState({});
  const [dataLength, setDatalength] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const loadingStatus = modelStatus.isPending;
  const isErrorValue =
    modelStatus.isError && modelStatus.error && modelStatus.error.status === 401 && modelStatus.error.status === 500;
  const errorDisplayVal = modelStatus.error;
  const loaderParams = {
    loadingStatus,
    isErrorValue,
    errorDisplayVal
  };

  useEffect(() => {
    const data = channelsUtilityData && Array.isArray(channelsUtilityData) && channelsUtilityData;
    setFilteredArray(data);
    setDatalength(channelsUtilityData.length);
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_CHATGPT_IES_USER_DATA, () => {
      const { userData } = ChatgptUtilityIESUserData;
      if (userData.length > 0) {
        setUserEmailDetails(userData[userData.length - 1]);
      }
    });
    Framework.getEventManager().on(constants.SET_CHATGPT_MAPPING_DATA, () => {
      const { toc } = chatgptMappingUtility;
      if (toc.length > 0) {
        setUserTocDetails(toc);
      }
    });
    Framework.getEventManager().on(constants.CHANNELS_SUB_TYPE_RESPONSE, () => {
      const resData = utils.processChannelsSubTypeResponse(channelsSubType);
      setSubTypeData(resData);
    });
    Framework.getEventManager().on(constants.CHANNELS_MODEL_RESPONSE, () => {
      const resData = utils.processChannelsSubTypeResponse(channelsModelData);
      if (resData.length > 0) {
        const [record] = resData;
        setDataValue(record);
      } else {
        setDataValue({});
      }
    });
  }, [channelsUtilityData, ChatgptUtilityIESUserData, userTocDetails]);

  /* eslint-disable no-param-reassign */
  const handlePageChange = (e) => {
    e.preventDefault();
    const { isNextPageKey } = props;
    let lastKey = {};
    if (isNextPageKey && isNextPageKey.ExclusiveStartKey) {
      const startKey = isNextPageKey.ExclusiveStartKey;
      lastKey = Object.entries(startKey).reduce((obj, [key, value]) => {
        if (key !== null && value !== null) {
          obj[key] = value;
        }
        return obj;
      }, {});
    }
    handleSearch({ lastKey: { ExclusiveStartKey: lastKey } });
  };

  const handleEvent = (params, index) => {
    const { userId, type, requestId } = params;
    channelsModelData.fetch({ userId, type, requestId });
    openChatDialog(true);
    setModalIndex(index);
    setSelectedValue(index);
  };

  const handleClose = () => {
    openChatDialog(false);
    setModalIndex(0);
    setSelectedValue(modalIndex);
    setSubTypeData([]);
  };

  const handleModalChange = (value) => {
    if (value === 'prev' && modalIndex > 0) {
      const { userId, type, requestId } = channelsUtilityData[modalIndex - 1];
      channelsModelData.fetch({ userId, type, requestId });
      setModalIndex(modalIndex - 1);
      setSubTypeData([]);
    } else {
      const { userId, type, requestId } = channelsUtilityData[modalIndex + 1];
      channelsModelData.fetch({ userId, type, requestId });
      setModalIndex(modalIndex + 1);
      setSubTypeData([]);
    }
  };

  const sorting = (value) => {
    if (order === 'ASC') {
      handleAscending('asc', value);
      SetOrder('DSC');
      setDirectionValue(value);
    }
    if (order === 'DSC') {
      handleAscending('des', value);
      SetOrder('ASC');
      setDirectionValue(value);
    }
  };

  const handleGETUserId = (userId) => {
    const { userData } = ChatgptUtilityIESUserData;
    if (userId) {
      const dataMatch = userData && userData.find((data) => data.userId === userId);
      if (dataMatch) {
        setUserEmailDetails(dataMatch);
      } else {
        ChatgptUtilityIESUserData.fetch({ userId });
      }
    }
  };

  const handleSelectedCheckBox = (value, sourceType) => {
    const { userId, requestId } = dataValue;
    if (subTypeData.length > 0) {
      const findValue = subTypeData.find((data) => data.requestId === requestId && data.type === sourceType);
      if (!findValue) {
        channelsSubType.fetch({ userId, type: value, requestId });
      }
    } else {
      channelsSubType.fetch({ userId, type: value, requestId });
    }
  };

  const onColumnClick = (val) => {
    sorting(val);
  };
  const onRowClick = (val, index) => {
    handleEvent(val, index);
  };
  const onMouseEnter = (val) => {
    handleGETUserId(val);
  };

  return (
    <>
      <ExportToCSV headers={ColumnConfig.ChannelsDataGridColumn} data={filteredArray} />
      <TableComponent
        columns={ColumnConfig.ChannelsDataGridColumn}
        data={filteredArray}
        onColumnClick={onColumnClick}
        order={order}
        onRowClick={onRowClick}
        selectedValue={selectedValue}
        directionValue={directionValue}
        userEmailDetails={userEmailDetails}
        onMouseEnter={onMouseEnter}
        setUserId={setUserId}
        userIdChecked={userIdChecked}
      />
      <br />
      <Box spacing={2}>
        <Stack sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
          {isNextPageKey &&
            isNextPageKey.ExclusiveStartKey &&
            isNextPageKey.ExclusiveStartKey.userId &&
            isNextPageKey.ExclusiveStartKey.userId.S !== null && (
              <Box>
                <ThemeProvider theme={themes}>
                  <Button
                    size="small"
                    color="primary"
                    className={classes.buttonStyle}
                    variant="contained"
                    onClick={(e) => handlePageChange(e)}
                  >
                    <Tooltip title="Load next set of data">Load More</Tooltip>
                  </Button>
                </ThemeProvider>
              </Box>
            )}
        </Stack>
      </Box>
      {openChat && (
        <DialogComponent
          openChat={openChat}
          dataValue={dataValue}
          userTocDetails={userTocDetails}
          dataLength={dataLength}
          modalIndex={modalIndex}
          handleClose={handleClose}
          handleModalChange={handleModalChange}
          subTypeData={subTypeData}
          handleSelectedCheckBox={handleSelectedCheckBox}
          loaderParams={loaderParams}
        />
      )}
    </>
  );
}

ChannelsDataGrid.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  channelsUtilityData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  handleAscending: PropTypes.func.isRequired,
  ChatgptUtilityIESUserData: shapes.modelOf(ChatgptUtilityIESUser).isRequired,
  chatgptMappingUtility: shapes.modelOf(ChatgptUtilityMapping).isRequired,
  isNextPageKey: PropTypes.object,
  channelsSubType: shapes.modelOf(SubTypeResponse),
  channelsModelData: shapes.modelOf(ChannelsModel),
  modelStatus: shapes.state.isRequired,
  userIdChecked: PropTypes.bool.isRequired,
  setUserId: PropTypes.func.isRequired
};

ChannelsDataGrid.defaultProps = {
  isNextPageKey: {},
  channelsSubType: [],
  channelsModelData: []
};

export default withStyles(styles)(
  observer(
    inject(
      'ChatgptUtilityIESUserData',
      'chatgptMappingUtility',
      'channelsSubType',
      'channelsModelData',
      'modelStatus'
    )(ChannelsDataGrid)
  )
);

import React from 'react';
import ValidatePermissions from '../../../users/ValidatePermissions';
import AIChartsComponent from './AIChartsComponent';

/* eslint-disable no-shadow */
function AIChartsView() {
  const showDashboardEnabled = false;
  return (
    <>
      <ValidatePermissions givenPermissions="admin_can_view" showDashboardEnabled={showDashboardEnabled}>
        <AIChartsComponent />
      </ValidatePermissions>
    </>
  );
}

AIChartsView.propTypes = {};

AIChartsView.defaultProps = {};

export default AIChartsView;

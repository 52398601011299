import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const chartsData = types.model('chartsData', {
  type: types.maybeNull(types.string),
  feedback: types.maybeNull(types.string),
  bookId: types.maybeNull(types.string),
  feedbackId: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  comments: types.maybeNull(types.string),
  reasonForDislike: types.maybeNull(types.string)
});

const AIChartsUtility = types
  .model('AIChartsUtility', {
    status: types.maybeNull(types.string),
    data: types.optional(types.array(chartsData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_AI_CHARTS_UTILITY_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.SET_AI_CHARTS_UTILITY_DATA);
    },
    resetStoreValues() {
      const initialState = {};
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.SET_AI_CHARTS_UTILITY_DATA);
    }
  }));

export default AIChartsUtility;

import React from 'react';
import ValidatePermissions from '../../../../users/ValidatePermissions';
import AIContentFilterCharts from './AIContentFilterCharts';

/* eslint-disable no-shadow */
function AIContentFilterView() {
  const showDashboardEnabled = false;
  return (
    <>
      <ValidatePermissions givenPermissions="admin_can_view" showDashboardEnabled={showDashboardEnabled}>
        <AIContentFilterCharts />
      </ValidatePermissions>
    </>
  );
}

AIContentFilterView.propTypes = {};

AIContentFilterView.defaultProps = {};

export default AIContentFilterView;

/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject } from 'mobx-react';
import { shapes } from '@greenville/framework';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Select,
  Typography,
  MenuItem
} from '@material-ui/core';
import UploadSourceFile from './UploadSourceFile';
import * as constants from '../../../../common/constants';

const styles = (theme) => ({
  root: {
    width: '98%',
    padding: theme.spacing(0, 1)
  },
  marginBottom: {
    marginBottom: 10
  },

  paddingRadioGrp: {
    padding: '0 6px'
  },
  labelRadioGrp: {
    fontSize: 14
  },
  paddingTopZero: {
    paddingTop: 0
  },
  alignForms: {
    display: 'flex',
    alignItems: 'center'
  },

  UploadText: {
    paddingBottom: theme.spacing(2)
  },
  labelWidht: {
    width: '100%'
  },
  uploadBox: {
    justifyContent: 'center'
  },
  boxItems: {
    display: 'flex'
  },
  gridClass: {
    justifyContent: 'flexStart',
    alignItems: 'center',
    paddingLeft: '20px'
  },
  boxWidth: {
    paddingRight: '20px'
  }
});

const IdentifierSearch = ({
  language,
  classes,
  data,
  handleOpenUploadContent,
  handleRadioButton,
  ingestionDeatils,
  eTextConfig,
  cmSubbusiness,
  cmDirectory,
  cmTitleTypeId,
  handleSelectionChange,
  cmBusinessValue,
  cmSubBusinessUnit,
  directoryId,
  cmIdentifier,
  commonSubBusinessUnit,
  commonDirectory,
  commonIdentifier,
  isIngestTab,
  DBFlag
}) => {
  const [radioButton, setRadioButton] = React.useState(ingestionDeatils.workflow);
  // const [requestNumber, setRequestNumber] = React.useState('12345');

  useEffect(() => {
    if (radioButton !== ingestionDeatils.workflow) {
      setRadioButton(ingestionDeatils.workflow);
    }
  });

  const handleChange = (event) => {
    setRadioButton(event.target.value);
    handleRadioButton(event.target.value);
  };

  return (
    <Box my={1} style={{ width: '100%' }}>
      <Grid container direction="row">
        <Grid xs={6}>
          <div>
            <Grid className={classes.marginBottom} direction="column" justify="space-between">
              <Box mb={0.5} className={classes.boxWidth}>
                <Typography variant="subtitle2">
                  <FormattedMessage {...language.getText('contentmanager.BUSINESS_UNIT')} />
                </Typography>

                <Select
                  className={classes.labelWidht}
                  name="businessUnit"
                  defaultValue="1"
                  value={cmBusinessValue}
                  onChange={(event) => handleSelectionChange(event, 'businessUnit')}
                >
                  {eTextConfig.businessUnit.map((filter) => (
                    <MenuItem value={filter.id}>{filter.value}</MenuItem>
                  ))}
                </Select>
              </Box>

              <Box mb={0.5} className={classes.boxWidth}>
                <Typography variant="subtitle2">
                  <FormattedMessage {...language.getText('contentmanager.SUBBUSINESS_UNIT')} />
                </Typography>

                <Select
                  className={classes.labelWidht}
                  name="cmSubbusiness"
                  error={commonSubBusinessUnit}
                  value={cmSubBusinessUnit}
                  onChange={(event) => handleSelectionChange(event, 'cmSubbusiness')}
                >
                  {cmSubbusiness
                    .filter((value) => value.businessUnitId === cmBusinessValue)

                    .map((cmvalue) => {
                      return <MenuItem value={cmvalue.subBusinessUnitId}>{cmvalue.subBusinessUnitName}</MenuItem>;
                    })}
                </Select>
              </Box>
              <Box mb={0.5} className={classes.boxWidth}>
                <Typography variant="subtitle2">
                  <FormattedMessage {...language.getText('contentmanager.DIRECTORY')} />
                </Typography>

                <Select
                  className={classes.labelWidht}
                  name="cmDirectory"
                  error={commonDirectory}
                  value={directoryId}
                  onChange={(event) => handleSelectionChange(event, 'cmDirectory')}
                >
                  <MenuItem value="directorySelect" disabled>
                    <FormattedMessage {...language.getText('common.select')} />
                  </MenuItem>

                  {cmDirectory
                    .filter((value) => value.subBusinessUnitId === cmSubBusinessUnit)
                    .map((cmvalue) => {
                      return (
                        <MenuItem value={cmvalue.directoryId} name={cmvalue.directoryName}>
                          {cmvalue.directoryName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Box>
              <br />
            </Grid>
          </div>
        </Grid>
        <Grid xs={6}>
          <div>
            <Grid container direction="column">
              <Grid container direction="row" className={classes.gridClass}>
                <Grid item className={classes.marginBottom} direction="row" justify="space-between">
                  <TextField
                    className={classes.labelWidht}
                    label={<FormattedMessage {...language.getText('contentmanager.IDENTIFIER')} />}
                    margin="dense"
                    name="identifier"
                    onChange={(event) => handleSelectionChange(event, 'identifier')}
                    value={cmIdentifier}
                    variant="outlined"
                    error={commonIdentifier}
                  />
                </Grid>
                <Grid item>
                  {cmTitleTypeId === 1 ? (
                    <Box ml={3}>
                      <RadioGroup
                        value={radioButton}
                        onChange={handleChange}
                        row
                        aria-label="eBook Workflow Radio group"
                      >
                        <FormControlLabel
                          className={classes.paddingTopZero}
                          value={constants.SIMPLE_EBOOK_WORKFLOW}
                          name="simpleRadioInput"
                          control={<Radio color="primary" className={classes.paddingRadioGrp} />}
                          label={
                            <span className={classes.labelRadioGrp}>
                              <FormattedMessage {...language.getText('contentmanager.SIMPLE')} />
                            </span>
                          }
                        />
                        <FormControlLabel
                          className={classes.paddingTopZero}
                          value={constants.STANDARD_EBOOK_WORKFLOW}
                          name="standardRadioInput"
                          control={<Radio color="primary" className={classes.paddingRadioGrp} />}
                          label={
                            <span className={classes.labelRadioGrp}>
                              <FormattedMessage {...language.getText('contentmanager.STANDARD')} />
                            </span>
                          }
                        />
                        {DBFlag ? (
                          <FormControlLabel
                            className={classes.paddingTopZero}
                            value={constants.SPDF_WORKFLOW}
                            name="spdfRadioInput"
                            control={<Radio color="primary" className={classes.paddingRadioGrp} />}
                            label={
                              <span className={classes.labelRadioGrp}>
                                <FormattedMessage {...language.getText('contentmanager.SPDF')} />
                              </span>
                            }
                          />
                        ) : (
                          ''
                        )}
                      </RadioGroup>
                    </Box>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={12}>
                  {cmTitleTypeId === 1 && radioButton === 'Simple eBook' ? (
                    <Box mb={0.5} mr={2}>
                      <Typography variant="subtitle2">
                        <FormattedMessage {...language.getText('contentmanager.SELECTED_EDITION')} />
                      </Typography>
                      <Select className={classes.labelWidht} name="bookEdition" defaultValue="0">
                        {eTextConfig.editionCm.map((filter) => (
                          <MenuItem value={filter.id}>{filter.value}</MenuItem>
                        ))}
                      </Select>
                    </Box>
                  ) : (
                    ''
                  )}
                  {DBFlag ? (
                    <Box mb={0.5} mr={1} className={classes.boxWidth}>
                      <Typography variant="subtitle2">
                        <FormattedMessage {...language.getText('contentmanager.TITLE_TYPE')} />
                      </Typography>

                      <Select
                        className={classes.labelWidht}
                        name="titleType"
                        defaultValue="1"
                        value={cmTitleTypeId}
                        onChange={(event) => handleSelectionChange(event, 'titleType')}
                      >
                        {eTextConfig.titleType.map((filter) => (
                          <MenuItem value={filter.id}>{filter.value}</MenuItem>
                        ))}
                      </Select>
                    </Box>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid>
        {isIngestTab && (
          <Box display="flex" className={classes.uploadBox}>
            <div className={classes.boxItems}>
              {' '}
              <br />
              {data && (
                <UploadSourceFile
                  DBFlag={DBFlag}
                  data={ingestionDeatils}
                  handleOpenUploadContent={() => handleOpenUploadContent()}
                />
              )}
            </div>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

IdentifierSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  data: PropTypes.string.isRequired,
  cmBusinessValue: PropTypes.string.isRequired,
  cmSubBusinessUnit: PropTypes.string.isRequired,
  directoryId: PropTypes.string.isRequired,
  cmTitleTypeId: PropTypes.string.isRequired,
  ingestionDeatils: PropTypes.object.isRequired,
  eTextConfig: PropTypes.object.isRequired,
  cmSubbusiness: PropTypes.object.isRequired,
  cmDirectory: PropTypes.object.isRequired,
  cmIdentifier: PropTypes.string.isRequired,
  handleOpenUploadContent: PropTypes.func.isRequired,
  handleRadioButton: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
  commonSubBusinessUnit: PropTypes.bool.isRequired,
  commonDirectory: PropTypes.bool.isRequired,
  commonIdentifier: PropTypes.bool.isRequired,
  isIngestTab: PropTypes.bool,
  DBFlag: PropTypes.bool.isRequired
};

IdentifierSearch.defaultProps = {
  isIngestTab: true
};

export default withStyles(styles)(inject('language')(IdentifierSearch));

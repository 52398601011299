import * as constants from '../constants';

const ChannelsDataGridColumn = [
  {
    key: 'serverDateTime',
    name: `${constants.DATE_TIME} (GMT)`,
    columnWidth: '190px'
  },
  {
    key: 'channelsId',
    name: constants.CHANNELS_ID,
    columnWidth: '160px'
  },
  {
    key: 'type',
    name: constants.TYPE,
    columnWidth: '100px'
  },
  {
    key: 'question',
    name: constants.GPT_TRENDS_USER_INPUT,
    columnWidth: '100px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const ChannelsListingDataGridColumn = [
  {
    key: 'dateStamp',
    name: constants.GPT_TRENDS_DATE,
    columnWidth: '190px'
  },
  {
    key: 'channelsId',
    name: constants.CHANNELS_ID,
    columnWidth: '160px'
  },
  {
    key: 'type',
    name: constants.TYPE,
    columnWidth: '100px'
  },
  {
    key: 'userCommand',
    name: constants.GPT_TRENDS_USER_INPUT,
    columnWidth: '100px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const EffectivenessDataGridColumn = [
  {
    key: 'input',
    name: 'Input',
    columnWidth: '250px'
  },
  {
    key: 'output',
    name: 'Output',
    columnWidth: '300px'
  },
  {
    key: 'Answer Relevance',
    name: 'Answer Relevance',
    columnWidth: '50px'
  },
  {
    key: 'Context Relevance',
    name: 'Context Relevance',
    columnWidth: '50px'
  },
  {
    key: 'Correctness',
    name: 'Correctness',
    columnWidth: '50px'
  },
  {
    key: 'Groundedness',
    name: 'Groundedness',
    columnWidth: '50px'
  }
];
const AdminPromptsDataGridColumn = [
  {
    key: 'type',
    name: constants.TYPE,
    columnWidth: '150px'
  },
  {
    key: 'promptStart',
    name: 'Prompt Start',
    columnWidth: '150px'
  },
  {
    key: 'promptEnd',
    name: 'Prompt End',
    columnWidth: '150px'
  },
  {
    key: 'version',
    name: 'Version',
    columnWidth: '50px'
  },
  {
    key: 'status',
    name: 'Status',
    columnWidth: '50px'
  },
  {
    key: 'createdDate',
    name: 'Date Created',
    columnWidth: '50px'
  },
  {
    key: 'updatedDate',
    name: 'Date Updated',
    columnWidth: '50px'
  },
  {
    key: 'modifiedBy',
    name: 'Modified By',
    columnWidth: '50px'
  }
];

const TenantsDataGridColumn = [
  {
    key: 'tenantName',
    name: 'Name',
    columnWidth: '100px'
  },
  {
    key: 'tenantId',
    name: 'Tenant ID',
    columnWidth: '100px'
  },
  {
    key: 'tenantKey',
    name: 'Tenant key',
    columnWidth: '100px'
  },
  {
    key: 'description',
    name: 'Description',
    columnWidth: '150px'
  }
];

const promptConfig = [
  {
    role: 'user',
    content: ''
  },
  {
    role: 'system',
    content: ''
  },
  {
    role: 'assistant',
    content: ''
  }
];

const CommonDataGridColumn = (param) => [
  {
    key: 'serverDateTime',
    name: `${constants.DATE_TIME} (GMT)`,
    columnWidth: '190px'
  },
  {
    key: 'bookId',
    name: param.bookId,
    columnWidth: '160px'
  },
  {
    key: 'type',
    name: constants.TYPE,
    columnWidth: '100px'
  },
  {
    key: 'question',
    name: constants.GPT_TRENDS_USER_INPUT,
    columnWidth: '100px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const CommonFeedbackColumn = (param) => [
  {
    key: 'serverDateTime',
    name: `${constants.DATE_TIME} (GMT)`,
    columnWidth: '160px'
  },
  {
    key: 'bookId',
    name: param.bookId,
    columnWidth: '150px'
  },
  {
    key: 'title',
    name: constants.TITLE,
    columnWidth: '100px'
  },
  {
    key: 'feedback',
    name: 'Feedback',
    columnWidth: '150px'
  },
  {
    key: 'reasonForDisLike',
    name: 'Reason for dislike',
    columnWidth: '150px'
  },
  {
    key: 'comments',
    name: 'Comments',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: constants.TYPE,
    columnWidth: '100px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const CommonGridListingColumn = (param) => [
  {
    key: 'dateStamp',
    name: constants.GPT_TRENDS_DATE,
    columnWidth: '190px'
  },
  {
    key: 'bookId',
    name: param.bookId,
    columnWidth: '160px'
  },
  {
    key: 'title',
    name: constants.TITLE,
    columnWidth: '100px'
  },
  {
    key: 'userCommand',
    name: constants.GPT_TRENDS_USER_INPUT,
    columnWidth: '100px'
  },
  {
    key: 'userId',
    name: constants.CHAT_USERLOGIN,
    columnWidth: '100px'
  }
];

const CommonTrendsDataGridColumn = (param) => [
  {
    key: 'bookId',
    name: param.bookId,
    columnWidth: '100px'
  },
  {
    key: 'date',
    name: constants.GPT_TRENDS_DATE,
    columnWidth: '100px'
  },
  {
    key: 'likeCount',
    name: constants.GPT_TRENDS_LIKECOUNT,
    columnWidth: '100px'
  },
  {
    key: 'disLikeCount',
    name: constants.GPT_TRENDS_DISLIKECOUNT,
    columnWidth: '100px'
  },
  {
    key: 'explainCount',
    name: constants.GPT_TRENDS_EXPLAINCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'summaryCount',
    name: constants.GPT_TRENDS_SUMMARYCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'quizMcqCount',
    name: constants.GPT_TRENDS_QUIZCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'unknownCount',
    name: constants.GPT_TRENDS_UNKNOWNCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'blockWordsCount',
    name: constants.GPT_TRENDS_BLOCKEDWORDCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredCount',
    name: constants.GPT_TRENDS_UNANSWEREDCOUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredNoCSGDocCount',
    name: constants.GPT_TRENDS_UNANSWERED_NO_CSG_DOCS_COUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredUnknownIntentCount',
    name: constants.GPT_TRENDS_UNANSWERED_UNKNOWN_INTENT_COUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredNoTopicFoundCount',
    name: constants.GPT_TRENDS_UNANSWERED_NO_TOPIC_FOUND_COUNT,
    columnWidth: '100px'
  },
  {
    key: 'unAnsweredProblemSolveCount',
    name: constants.GPT_TRENDS_BLOCKED_COUNT,
    columnWidth: '100px'
  }
];

const PVSAssessmentGridColumns = [
  {
    key: 'assessmentName',
    name: 'Assessment Name',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: 'Type',
    columnWidth: '120px'
  },
  {
    key: 'id',
    name: 'ID',
    columnWidth: '100px'
  },
  {
    key: 'studentsAssigned',
    name: 'Students Assigned',
    columnWidth: '100px'
  },
  {
    key: 'teachers',
    name: 'Teachers',
    columnWidth: '100px'
  },

  {
    key: 'created',
    name: 'Created',
    columnWidth: '100px'
  },
  {
    key: 'lastModified',
    name: 'Last Modified',
    columnWidth: '100px'
  },
  {
    key: 'status',
    name: 'Status',
    columnWidth: '100px'
  }
];

const AIStudyChartsFeedbackColumn = [
  {
    key: 'bookId',
    name: 'Book ID',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: 'Type',
    columnWidth: '100px'
  },
  {
    key: 'feedback',
    name: 'Feedback',
    columnWidth: '150px'
  },
  {
    key: 'reasonForDisLike',
    name: 'Reason for dislike',
    columnWidth: '150px'
  },
  {
    key: 'comments',
    name: 'Comments',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: constants.TYPE,
    columnWidth: '100px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const AIContentFilterChartsViolenceColumn = [
  {
    key: 'usercommand',
    name: 'User Command',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: 'Type',
    columnWidth: '100px'
  },
  {
    key: 'violenceCategory',
    name: 'Violence',
    columnWidth: '150px'
  },
  {
    key: 'violenceSeverity',
    name: 'Severity',
    columnWidth: '150px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const AIContentFilterChartsHateColumn = [
  {
    key: 'usercommand',
    name: 'User Command',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: 'Type',
    columnWidth: '100px'
  },
  {
    key: 'hateCategory',
    name: 'Hate',
    columnWidth: '150px'
  },
  {
    key: 'hateSeverity',
    name: 'Severity',
    columnWidth: '150px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const AIContentFilterChartsSelfHarmColumn = [
  {
    key: 'usercommand',
    name: 'User Command',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: 'Type',
    columnWidth: '100px'
  },
  {
    key: 'selfharmCategory',
    name: 'Self Harm',
    columnWidth: '150px'
  },
  {
    key: 'selfharmSeverity',
    name: 'Severity',
    columnWidth: '150px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const AIContentFilterChartsSexualColumn = [
  {
    key: 'usercommand',
    name: 'User Command',
    columnWidth: '150px'
  },
  {
    key: 'type',
    name: 'Type',
    columnWidth: '100px'
  },
  {
    key: 'sexualCategory',
    name: 'Sexual',
    columnWidth: '150px'
  },
  {
    key: 'sexualSeverity',
    name: 'Severity',
    columnWidth: '150px'
  },
  {
    key: 'userId',
    name: constants.USER_ID,
    columnWidth: '100px'
  }
];

const ColumnConfig = {
  ChannelsDataGridColumn,
  ChannelsListingDataGridColumn,
  EffectivenessDataGridColumn,
  AdminPromptsDataGridColumn,
  TenantsDataGridColumn,
  promptConfig,
  CommonDataGridColumn,
  CommonFeedbackColumn,
  CommonGridListingColumn,
  CommonTrendsDataGridColumn,
  PVSAssessmentGridColumns,
  AIStudyChartsFeedbackColumn,
  AIContentFilterChartsSexualColumn,
  AIContentFilterChartsSelfHarmColumn,
  AIContentFilterChartsHateColumn,
  AIContentFilterChartsViolenceColumn
};

export default ColumnConfig;

import MomentUtils from '@date-io/moment';
import Framework, { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import { Box, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from '../../../../common/Loader';
import ChannelsDataGrid from './datagrid/ChannelsDataGrid';
import * as constants from '../../../../common/constants';
import ChannelsUtilityModel from '../../models/ChannelsUtility';
import ChatgptAdmin from '../../models/ChatgptAdmin';

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      yearSelected: {
        color: constants.PEARSON_PRIMARY_COLOR
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      daySelected: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: constants.PEARSON_PRIMARY_COLOR
        }
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    }
  }
});

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = (theme) => ({
  datePicker: {
    marginTop: 'inherit'
  },
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  buttonStyle: {
    width: '120px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -20
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  }
});
@inject('channelsUtility', 'channelsUtilityStatus', 'chatgptAdmin', 'ChatGptUtilityAdminStatus')
@observer
class ChannelsComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    channelsUtility: shapes.modelOf(ChannelsUtilityModel).isRequired,
    channelsUtilityStatus: shapes.state.isRequired,
    chatgptAdmin: shapes.modelOf(ChatgptAdmin).isRequired,
    ChatGptUtilityAdminStatus: shapes.state.isRequired
  };

  constructor(props) {
    super(props);
    const { channelsUtility } = props;
    this.defaultSearch = {
      channelsId: 'general-chemistry',
      userId: '',
      email: '',
      startDateTime: '',
      endDateTime: '',
      type: ''
    };
    this.state = {
      newRequest: true,
      searchFields: {
        channelsId: 'general-chemistry'
      },
      channelsResponse: channelsUtility,
      endDisabled: true,
      loaderStatus: true,
      types: [],
      userIdChecked: false
    };
  }

  componentDidMount = () => {
    const { channelsUtility, chatgptAdmin } = this.props;
    // channelsUtility.fetch();
    chatgptAdmin.fetchTenantData('channels');
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_ADMIN_DATA, () => {
      const { groups } = chatgptAdmin;
      if (groups && groups.length > 0) {
        const typesData = groups.filter((item) => item.name === 'Types')[0];
        this.setState({ types: typesData });
      }
    });
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.CHANNELS_RESPONSE, () => {
      const { data } = channelsUtility;
      if (channelsUtility) {
        const { newRequest, channelsResponse } = this.state;
        const combineDatatoJS = data;
        /* eslint-enable camelcase */
        if (newRequest) {
          const sortedDate = combineDatatoJS.sort((x, y) => new Date(y.serverDateTime) - new Date(x.serverDateTime));
          this.setState({ channelsResponse: sortedDate });
        } else {
          const combineDataGpt = [...channelsResponse, ...combineDatatoJS];
          const sortedDate = combineDataGpt.sort((x, y) => new Date(y.serverDateTime) - new Date(x.serverDateTime));
          this.setState(() => ({ channelsResponse: sortedDate }));
        }
      }
    });
  };

  handleChange = (e) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    fieldChanged[e.target.name] = e.target.value;
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged }
    });
  };

  handleDateChange = (date, name) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    fieldChanged[name] = moment(date).format(constants.MM_DD_YYYY_FORMAT);
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged }
    });
    const payload = fieldChanged;
    if (payload.startDateTime) {
      this.setState({
        endDisabled: false
      });
    }
  };

  handleReset = () => {
    this.setState({
      endDisabled: true,
      searchFields: {
        ...this.defaultSearch
      },
      userIdChecked: false
    });
  };

  handleSearch = (customPayload) => {
    const { searchFields } = this.state;
    const { channelsUtility } = this.props;
    if (searchFields.userId) {
      this.setState({ userIdChecked: true });
    } else {
      this.setState({ userIdChecked: false });
    }
    if (customPayload && customPayload.lastKey) {
      this.setState({ newRequest: false });
    } else {
      this.setState({ newRequest: true });
    }

    const payload = { ...searchFields, ...customPayload };
    if (payload && (payload.channelsId || payload.email || payload.userId || payload.type)) {
      channelsUtility.fetch(payload);
    }
  };

  handleAscending = (dir, value) => {
    const { channelsResponse } = this.state;
    if (dir === 'asc') {
      const sorted = [...channelsResponse].sort((a, b) =>
        a[value] && b[value] && a[value].toLowerCase() > b[value].toLowerCase() ? 1 : -1
      );
      this.setState({
        ...channelsResponse,
        channelsResponse: sorted
      });
    } else {
      const sorted = [...channelsResponse].sort((a, b) =>
        a[value] && b[value] && a[value].toLowerCase() < b[value].toLowerCase() ? 1 : -1
      );
      this.setState({
        ...channelsResponse,
        channelsResponse: sorted
      });
    }
  };

  setUserId = (field, checkedUserId) => {
    const { searchFields, userIdChecked } = this.state;
    const fieldChanged = {};
    if (userIdChecked) {
      fieldChanged[field] = '';
      this.setState(
        {
          searchFields: { ...searchFields, ...fieldChanged },
          userIdChecked: false
        },
        () => {
          this.handleSearch();
        }
      );
    } else {
      fieldChanged[field] = checkedUserId;
      this.setState(
        {
          searchFields: { ...searchFields, ...fieldChanged },
          userIdChecked: true
        },
        () => {
          this.handleSearch();
        }
      );
    }
  };

  render() {
    const {
      classes,
      channelsUtilityStatus,
      ChatGptUtilityAdminStatus,
      channelsUtility: { isNextPageKey }
    } = this.props;
    const { searchFields, endDisabled, loaderStatus, channelsResponse, types, userIdChecked } = this.state;
    return (
      <>
        <Paper>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ p: 2 }}>
              <Typography variant="h5" align="center" gutterBottom spacing={9}>
                {constants.CHAT_CRITERIA_TEXT}
              </Typography>
            </Box>
            <Grid container direction="row">
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', p: '0px 33px' }}>
                  <TextField
                    label="Channels Id"
                    margin="dense"
                    name="channelsId"
                    onChange={($event) => this.handleChange($event)}
                    style={{ minWidth: '32%', marginTop: '0px' }}
                    value={searchFields.channelsId}
                    variant="outlined"
                  />
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ThemeProvider theme={materialTheme}>
                      <KeyboardDatePicker
                        className={classes.datePicker}
                        format={constants.MM_DD_YYYY_FORMAT}
                        autoOk
                        size="small"
                        variant="inline"
                        style={{ minWidth: '32%' }}
                        inputVariant="outlined"
                        disableFuture
                        value={searchFields.startDateTime || null}
                        label="Start Date"
                        onChange={(date) => this.handleDateChange(date, 'startDateTime')}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                      <KeyboardDatePicker
                        className={classes.datePicker}
                        format={constants.MM_DD_YYYY_FORMAT}
                        autoOk
                        variant="inline"
                        size="small"
                        style={{ minWidth: '32%' }}
                        inputVariant="outlined"
                        disableFuture
                        value={searchFields.endDateTime || null}
                        label="End Date"
                        onChange={(date) => this.handleDateChange(date, 'endDateTime')}
                        disabled={endDisabled}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </Box>
              </Grid>
              <Grid item xs={1} />
            </Grid>
            <br />
            <Grid container direction="row">
              <Grid item xs={1} />
              <Grid item xs={10} direction="row">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
                  <TextField
                    label={searchFields.userId ? '' : 'User ID'}
                    margin="dense"
                    name="userId"
                    onChange={($event) => this.handleChange($event)}
                    style={{ minWidth: '32%' }}
                    value={searchFields.userId}
                    variant="outlined"
                  />
                  <TextField
                    label="Email ID"
                    margin="dense"
                    type="email"
                    style={{ minWidth: '32%' }}
                    name="email"
                    onChange={($event) => this.handleChange($event)}
                    value={searchFields.email}
                    variant="outlined"
                  />
                  <FormControl sx={{ mt: 1, width: '32%' }} size="small">
                    <InputLabel id="demo-simple-select-autowidth-label">Type</InputLabel>
                    <Select
                      label="Type"
                      name="type"
                      onChange={($event) => this.handleChange($event)}
                      value={searchFields.type || ''}
                    >
                      {types &&
                        types.properties &&
                        types.properties.map((item) => <MenuItem value={item.id}>{item.display_name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={1} />
            </Grid>
            <br />
          </Box>
          <br />
          <Box>
            <Grid container justify="space-evenly" className={classes.buttonGroupStyle}>
              <ThemeProvider theme={themes}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => this.handleSearch()}
                  className={classes.buttonStyle}
                >
                  {constants.CHAT_SEARCH}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={this.handleReset}
                  className={classes.buttonStyle}
                >
                  {constants.CHAT_RESET}
                </Button>
              </ThemeProvider>
            </Grid>
            <br />
          </Box>
        </Paper>
        <LoadingHandler
          loading={channelsUtilityStatus.isPending || ChatGptUtilityAdminStatus.isPending}
          isError={
            channelsUtilityStatus.isError &&
            channelsUtilityStatus.error &&
            channelsUtilityStatus.error.status === 401 &&
            channelsUtilityStatus.error.status === 500
          }
          content={
            channelsResponse && channelsResponse.length > 0 ? (
              <Box>
                <br />
                <ChannelsDataGrid
                  channelsUtilityData={channelsResponse}
                  handleAscending={this.handleAscending}
                  isNextPageKey={isNextPageKey}
                  handleSearch={this.handleSearch}
                  setUserId={this.setUserId}
                  userIdChecked={userIdChecked}
                />
              </Box>
            ) : (
              <>
                <br />
                <Paper>
                  <br />
                  <Box>
                    <br />
                    <Typography
                      variant="h4"
                      align="center"
                      gutterBottom
                      spacing={9}
                      className={classes.title}
                      style={{ p: 5, mt: 25 }}
                    >
                      {constants.CHAT_FETCH}
                    </Typography>
                  </Box>
                </Paper>
              </>
            )
          }
          loadingContent={<Loader loaderStatus={loaderStatus} />}
          errorContent={<StateErrorDisplay error={channelsUtilityStatus.error} showDetails />}
        />
      </>
    );
  }
}

export default withStyles(styles)(ChannelsComponent);

import React from 'react';
import ValidatePermissions from '../../../../users/ValidatePermissions';
import AITopicCharts from './AITopicCharts';

/* eslint-disable no-shadow */
function AITopicView() {
  const showDashboardEnabled = false;
  return (
    <>
      <ValidatePermissions givenPermissions="admin_can_view" showDashboardEnabled={showDashboardEnabled}>
        <AITopicCharts />
      </ValidatePermissions>
    </>
  );
}

AITopicView.propTypes = {};

AITopicView.defaultProps = {};

export default AITopicView;

import Framework, { shapes } from '@greenville/framework';
import { Box, Button, withStyles, Paper, Grid } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import React, { useEffect, useState } from 'react';
import TableComponent from '../../../../../common/components/Table';
import ColumnConfig from '../../../../../common/config/ColumnConfig';
import * as constants from '../../../../../common/constants';
import ChatgptUtilityIESUser from '../../../models/ChatgptIESUser';
import ChatgptUtilityMapping from '../../../models/ChatgptMapping';
import ResponseModel from '../../../models/Response';
import DynamicDialog from './DynamicDialog';
import DynamicListing from './DynamicListing';

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = () => ({
  buttonStyle: {
    cursor: 'pointer',
    width: '170px',
    margin: '10px',
    fontSize: '14px',
    background: '#005d83 !important'
  }
});

const DynamicDataGrid = React.memo((props) => {
  const {
    gridData,
    handleAscending,
    classes,
    isNextPageKey,
    handleSearch,
    selectedTab,
    clusterState,
    ChatgptUtilityIESUserData,
    chatgptResponseUtility,
    chatgptMappingUtility,
    chatgptUtilityResponseStatus,
    tenantName,
    tenantTitle
  } = props;
  const isFeedback = selectedTab === 1;
  const [filteredArray, setFilteredArray] = useState([]);
  const [userEmailDetails, setUserEmailDetails] = useState({});
  const [userTocDetails, setUserTocDetails] = useState({});
  const [openChat, openChatDialog] = useState(false);
  const [loaderStatusResponse] = useState(true);
  const [dataValue, setDataValue] = useState({});
  const [dataLength, setDatalength] = useState({});
  const [directionValue, setDirectionValue] = useState('');
  const [rowDialogue, setRowDialogue] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState('');
  const [order, SetOrder] = useState('ASC');

  useEffect(() => {
    const data = gridData && Array.isArray(gridData) && gridData;
    setFilteredArray(data);
    setDatalength(gridData.length);
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_CHATGPT_IES_USER_DATA, () => {
      const { userData } = ChatgptUtilityIESUserData;
      if (userData.length > 0) {
        setUserEmailDetails(userData[userData.length - 1]);
      }
    });
    Framework.getEventManager().on(constants.SET_CHATGPT_MAPPING_DATA, () => {
      const { toc } = chatgptMappingUtility;
      if (toc.length > 0) {
        setUserTocDetails(toc);
      }
    });
    let rowDialogProcessed = {};
    let rowProcessed = {};
    Framework.getEventManager().on(constants.GET_CHATGPT_RESPONSE_MODEL_DATA, () => {
      const { summary, quizMcq, discuss, quizIntent, mcq, commonAuditData } = chatgptResponseUtility;
      if (chatgptResponseUtility) {
        const summaryData = summary && summary.length > 0 ? summary : [];
        const quizMcqData = quizMcq && quizMcq.length > 0 ? quizMcq : [];
        const discussData = discuss && discuss.length > 0 && discuss ? discuss : [];
        const quizIintentData = quizIntent && quizIntent.length > 0 ? quizIntent : [];
        const mcqData = mcq && mcq.length > 0 ? mcq : [];
        const commonAuditDataVal = commonAuditData && commonAuditData.length > 0 ? commonAuditData : [];
        const combineData = [
          ...summaryData,
          ...discussData,
          ...quizMcqData,
          ...quizIintentData,
          ...mcqData,
          ...commonAuditDataVal
        ];
        /* eslint-disable no-param-reassign */
        if (combineData && combineData[0] && combineData[0].type === 'chat_intent') {
          try {
            combineData[0].botResponse = JSON.parse(combineData[0].botResponse);
          } catch (err) {
            const { botResponse } = combineData[0];
            combineData[0] = { ...combineData[0], botResponse: botResponse[0] };
          }
        }
        const combineDatatoJS = JSON.parse(JSON.stringify(toJS(combineData)));
        if (combineDatatoJS[0] && Object.keys(combineDatatoJS[0]).length > 0) {
          rowProcessed = Object.entries(combineDatatoJS[0]).reduce((obj, [key, value]) => {
            if (key !== null && value !== null) {
              obj[key] = value;
            }
            return obj;
          }, {});
          if (rowProcessed.serverDateTime && isFeedback) {
            delete rowProcessed.serverDateTime;
          }
          if (rowDialogue && Object.keys(rowDialogue).length > 0) {
            rowDialogProcessed = Object.entries(rowDialogue).reduce((obj, [key, value]) => {
              if (key !== null && value !== null) {
                obj[key] = value;
              }
              return obj;
            }, {});
          }

          if (rowProcessed.type === 'chat_intent') {
            const { botResponse } = rowProcessed;
            let processBotResponse = '';
            try {
              processBotResponse = [JSON.parse(botResponse)];
            } catch (err) {
              const splitTwoText = botResponse.split('[BAD-GPT]');
              const normGPT = JSON.parse(splitTwoText[0].split('[NORM-GPT]')[1]);
              const badGPT = JSON.parse(splitTwoText[1]);
              processBotResponse = [normGPT, badGPT];
            }
            rowProcessed.botResponse = processBotResponse;
          }
          if (rowProcessed.type === constants.CONTEXT_IDENTIFIER_USER_SELECTION) {
            const { botResponse } = rowProcessed;
            rowProcessed.botResponse = [botResponse];
          }

          const rowChangedValue = {
            ...rowDialogProcessed,
            ...rowProcessed
          };

          /* eslint-enable camelcase */
          setDataValue(rowChangedValue);
        } else if (isFeedback && rowDialogue) {
          setDataValue(rowDialogue);
        }
      }
    });
  }, [
    gridData,
    isNextPageKey,
    chatgptResponseUtility,
    rowDialogue,
    userEmailDetails,
    userTocDetails,
    ChatgptUtilityIESUserData
  ]);

  const sorting = (value) => {
    if (order === 'ASC') {
      handleAscending('asc', value);
      SetOrder('DSC');
      setDirectionValue(value);
    }
    if (order === 'DSC') {
      handleAscending('des', value);
      SetOrder('ASC');
      setDirectionValue(value);
    }
  };

  const handleEvent = (params, index) => {
    const { requestId, bookId, type } = params;
    setRowDialogue(params);
    openChatDialog(true);
    setModalIndex(index);
    setSelectedValue(index);
    if (isFeedback) {
      chatgptResponseUtility.fetch({ requestId, bookId });
    } else {
      chatgptResponseUtility.fetch({ requestId, bookId, type });
    }
  };

  const handleClose = () => {
    openChatDialog(false);
    setModalIndex(1);
    setDataValue({});
  };

  const onColumnClick = (val) => {
    sorting(val);
  };
  const onRowClick = (val, index) => {
    handleEvent(val, index);
  };
  const hanldeGETUserId = (userId) => {
    const { userData } = ChatgptUtilityIESUserData;
    if (userId) {
      const dataMatch = userData && userData.find((data) => data.userId === userId);
      if (dataMatch) {
        setUserEmailDetails(dataMatch);
      } else {
        ChatgptUtilityIESUserData.fetch({ userId });
      }
    }
  };
  const onMouseEnter = (val) => {
    hanldeGETUserId(val);
  };

  const handleModalChange = (value) => {
    if (value === 'prev' && modalIndex > 0) {
      const { requestId, bookId, type } = gridData[modalIndex - 1];
      setRowDialogue(gridData[modalIndex - 1]);
      setModalIndex(modalIndex - 1);
      if (isFeedback) {
        chatgptResponseUtility.fetch({ requestId, bookId });
      } else {
        chatgptResponseUtility.fetch({ requestId, bookId, type });
      }
    } else {
      const { requestId, bookId, type } = gridData[modalIndex + 1];
      setRowDialogue(gridData[modalIndex + 1]);
      setModalIndex(modalIndex + 1);
      if (isFeedback) {
        chatgptResponseUtility.fetch({ requestId, bookId });
      } else {
        chatgptResponseUtility.fetch({ requestId, bookId, type });
      }
    }
  };

  /* eslint-disable no-param-reassign */
  const handlePageChange = (e) => {
    e.preventDefault();
    let lastKey = {};
    if (isNextPageKey && isNextPageKey.ExclusiveStartKey) {
      const startKey = isNextPageKey.ExclusiveStartKey;
      lastKey = Object.entries(startKey).reduce((obj, [key, value]) => {
        if (key !== null && value !== null) {
          obj[key] = value;
        }
        return obj;
      }, {});
    }
    handleSearch({ lastKey: { ExclusiveStartKey: lastKey } });
  };

  const getColumnConfig = () => {
    const bookIdName = { bookId: tenantName !== 'Reader+' ? 'Course Id' : 'Book Id' };
    if (selectedTab === 0) {
      return ColumnConfig.CommonDataGridColumn(bookIdName);
    }
    if (selectedTab === 1) {
      return ColumnConfig.CommonFeedbackColumn(bookIdName);
    }
    if (selectedTab === 2) {
      if (clusterState === 'count') {
        return ColumnConfig.CommonTrendsDataGridColumn(bookIdName);
      }
    }
    return [];
  };

  return (
    <>
      {selectedTab === 0 || selectedTab === 1 || (selectedTab === 2 && clusterState === 'count') ? (
        <>
          <TableComponent
            columns={getColumnConfig()}
            data={filteredArray}
            onColumnClick={onColumnClick}
            order={order}
            onRowClick={clusterState === 'count' ? () => {} : onRowClick}
            selectedValue={selectedValue}
            directionValue={directionValue}
            userEmailDetails={userEmailDetails}
            onMouseEnter={onMouseEnter}
            isFromCommonDataGrid
            isFromChannelsTrends={clusterState === 'count'}
          />
          <br />
          <Box spacing={2}>
            <Stack sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
              {isNextPageKey &&
                isNextPageKey.ExclusiveStartKey &&
                isNextPageKey.ExclusiveStartKey.userId &&
                isNextPageKey.ExclusiveStartKey.userId.S !== null && (
                  <Box>
                    <ThemeProvider theme={themes}>
                      <Button
                        size="small"
                        color="primary"
                        className={classes.buttonStyle}
                        variant="contained"
                        onClick={(e) => handlePageChange(e)}
                      >
                        <Tooltip title="Load next set of data">Load More</Tooltip>
                      </Button>
                    </ThemeProvider>
                  </Box>
                )}
            </Stack>
          </Box>
        </>
      ) : (
        <Paper>
          <Grid item xs={12}>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', p: '0px 33px' }}>
              <br />
              <DynamicListing listingData={gridData} tenantName={tenantName} tenantTitle={tenantTitle} />
            </Box>
          </Grid>
        </Paper>
      )}
      {openChat && (
        <DynamicDialog
          openChat={openChat}
          dataValue={dataValue}
          userTocDetails={userTocDetails}
          dataLength={dataLength}
          modalIndex={modalIndex}
          filterPromt={constants.FILTER_PROMPT}
          isFeedback={isFeedback}
          loaderStatusResponse={loaderStatusResponse}
          chatgptUtilityResponseStatus={chatgptUtilityResponseStatus}
          handleClose={handleClose}
          handleModalChange={handleModalChange}
          tenantTitle={tenantTitle}
        />
      )}
    </>
  );
});

DynamicDataGrid.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  gridData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  handleAscending: PropTypes.func.isRequired,
  isNextPageKey: PropTypes.object,
  selectedTab: PropTypes.string,
  clusterState: PropTypes.string.isRequired,
  chatgptResponseUtility: shapes.modelOf(ResponseModel).isRequired,
  chatgptUtilityResponseStatus: shapes.state.isRequired,
  ChatgptUtilityIESUserData: shapes.modelOf(ChatgptUtilityIESUser).isRequired,
  chatgptMappingUtility: shapes.modelOf(ChatgptUtilityMapping).isRequired,
  tenantName: PropTypes.string,
  tenantTitle: PropTypes.string
};

DynamicDataGrid.defaultProps = {
  isNextPageKey: {},
  selectedTab: '',
  tenantName: '',
  tenantTitle: ''
};

export default withStyles(styles)(
  observer(
    inject(
      'chatgptResponseUtility',
      'chatgptUtilityResponseStatus',
      'ChatgptUtilityIESUserData',
      'chatgptMappingUtility'
    )(DynamicDataGrid)
  )
);

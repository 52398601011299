import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TableComponent from '../../../../common/components/Table';
import ColumnConfig from '../../../../common/config/ColumnConfig';
import * as constants from '../../../../common/constants';
import DialogBox from '../../../../common/components/DialogBox';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#005d83 !important',
    color: 'white !important'
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'end',
    width: '70%'
  },
  searchFieldDiv: {
    display: 'contents'
  }
});

const DEMO_DATA = [
  {
    assessmentName: 'Custom 2024',
    type: 'Quick Check',
    id: 'C4357Y6790',
    studentsAssigned: '2',
    teachers: 'Shardon Zefras',
    created: '30/04/2024',
    lastModified: '30/04/2024',
    status: 'Published'
  },
  {
    assessmentName: 'New Custom Copy',
    type: '',
    id: 'C4357Y567V',
    studentsAssigned: 'Not Asssigned',
    teachers: 'Shardon Zefras',
    created: '30/04/2024',
    lastModified: '30/04/2024',
    status: 'Not Published'
  },
  {
    assessmentName: 'Custom Assesment 2024',
    type: 'Quick Check',
    id: 'C43545679A',
    studentsAssigned: '1',
    teachers: 'Shardon Zefras',
    created: '30/04/2024',
    lastModified: '30/04/2024',
    status: 'Published'
  }
];

const AllAssessments = () => {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [itemName, setItemName] = useState('');

  useEffect(() => {
    setData(DEMO_DATA);
  }, []);

  const handleCreateCustomAssessment = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleTextFieldHandler = (e) => {
    e.preventDefault();
    setItemName(e.target.value);
  };
  const handleConfirmAction = () => {
    setData([
      ...data,
      {
        assessmentName: itemName,
        type: 'Quick Check',
        id: 'C435Y5237V',
        studentsAssigned: 'Not Asssigned',
        teachers: 'Shardon Zefras',
        created: '30/04/2024',
        lastModified: '30/04/2024',
        status: 'Not Published'
      }
    ]);
  };

  const handleAssessment = (event, item) => {
    history.push('/chatgptutility/pvsassessment/selectlesson', { data: item });
  };

  return (
    <>
      <Box>All Custom Assessments</Box>
      <Box sx={{ display: 'flex', paddingTop: '15px', width: '105%' }}>
        <div className={classes.searchFieldDiv}>
          <TextField
            style={{ width: '50%' }}
            variant="outlined"
            placeholder="Search by assessments name, teachers etc..."
            size="small"
            InputProps={{
              startAdornment: <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)', marginRight: 4 }} />
            }}
          />
        </div>
        <div className={classes.buttonContent}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreateCustomAssessment}
            className={classes.button}
          >
            Create Custom Assessment
          </Button>
        </div>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '15px', width: '105%' }}>
        <TableComponent
          columns={ColumnConfig.PVSAssessmentGridColumns}
          data={data}
          onColumnClick={() => {}}
          order="ASC"
          onRowClick={() => {}}
          directionValue=""
          userEmailDetails=""
          onMouseEnter={() => {}}
          isFromChannelsTrends
          isFromPVSAssesment
          handleAssessment={handleAssessment}
        />
      </Box>
      {openDialog && (
        <DialogBox
          open={openDialog}
          handleClose={handleClose}
          title={constants.CUSTOM_ASSESSMENT}
          handleConfirm={handleConfirmAction}
          handleTextFieldHandler={handleTextFieldHandler}
          itemName={itemName}
          label={constants.CUSTOM_ASSESSMENT_NAME}
          isFromCustomAssessment
        />
      )}
    </>
  );
};

export default AllAssessments;

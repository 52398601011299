import React from 'react';
import ChatgptUtilityService from './service/ChatGptUtilityService';
import ChatGptUtilityFeedbackService from './service/ChatGptUtilityFeedbackService';
import ChatGptUtilityResponseService from './service/ChatGptUtilityResponseService';
import ChatgptUtility from './models/ChatgptUtility';
import ChannelsUtility from './models/ChannelsUtility';
import ChannelsListing from './models/ChannelsListing';
import ChannelsTrends from './models/ChannelsTrends';
import Feedback from './models/Feedback';
import Response from './models/Response';
import Trends from './models/Trends';
import chatutilityIESData from './models/ChatgptIESUser';
import SubTypeResponse from './models/SubTypeModel';
import Mapping from './models/ChatgptMapping';
import Listing from './models/Listing';
import AssetTOC from './models/AssetTOC';
import Toc from './models/Toc';
import McqSearch from './models/McqSearch';
import ChatgptAdmin from './models/ChatgptAdmin';
import CommonSearchModel from './models/CommonSearchModel';
import ChatGptUtilityContainer from './ChatGptUtilityContainer';
import ChatgptUtilityIESService from './service/ChatGptUtilityIESService';
import ChatGptUtilityTrendsService from './service/ChatGptUtilityTrendsService';
import ChatGptUtilityMappingService from './service/ChatGptUtilityMappingService';
import ChatGptUtilitySubTypeService from './service/ChatUtilitySubTypeService';
import ChatGptUtilityListingService from './service/ChatGptUtilityListingService';
import AssetService from './service/AssetService';
import ChatGptUtilityTocService from './service/ChatGptUtilityTocService';
import ChatGptUtilitySummaryService from './service/ChatGptUtilitySummaryService';
import ChatGptUtilitySummaryUpdateService from './service/ChatGptUtilitySummaryUpdateService';
import ChatGptUtilitySummaryStatusUpdateService from './service/ChatGptUtilitySummaryStatusUpdateService';
import ChatGptPublishAllSummaryStatusUpdateService from './service/ChatGptPublishAllSummaryUpdateService';
import ChatGptUtilityMcqEditService from './service/ChatGptUtilityMcqEditService';
import ChatGptUtilityTocAddService from './service/ChatGptUtilityTocAddService';
import McqStatusDeleteService from './service/McqStatusDeleteService';
import McqStatusUpdateService from './service/McqStatusUpdateService';
import McqPublishAllStatusUpdateService from './service/McqPublishAllStatusUpdateService';
import McqUtilityBookSearchService from './service/McqUtilityBookSearchService';
import ChannelsUtilityService from './service/ChannelsUtilityService';
import ChannelsUtilityTrendsListingService from './service/ChannelsUtilityTrendsListingService';
import ChannelsUtilitytrendsService from './service/ChannelsUtilityTrendsService';
import ChannelsSubType from './models/ChannelsSubType';
import ChannelsSubTypeService from './service/ChannelsSubTypeService';
import ChannelsModel from './models/ChannelsModel';
import ChannelsModelService from './service/ChannelsModelService';
import Summary from './models/Summary';
import ChatgptAdminService from './service/ChatgptAdminService';
import ChatGptGetAdminervice from './service/ChatgptGetAdminService';
import ChatGptEditAdminService from './service/ChatgptEditAdminService';
import ChatGptGetAdminPromptsService from './service/ChatGptGetAdminPromptsService';
import ChatgptAdminPromptsData from './models/ChatGptAdminPrompts';
import ChatGptAddAdminPromptsService from './service/ChatGptAddAdminPromptsService';
import ChatGptEditAdminPromptsService from './service/ChatGptEditAdminPromptsService';
import EffectivenessData from './models/StudyToolEffectiveness';
import StudyToolEffectivenessService from './service/StudyToolEffectivenessService';
import EffectivenessInitiateService from './service/EffectivenessInitiateService';
import EffectivenessMetadataService from './service/EffectivenessMetadataService';
import CommonSearchUtilityService from './service/CommonSearchUtilityService';
import ChatutilityUsers from './models/ChatutilityUsers';
import ChatutilityRoles from './models/ChatutilityRoles';
import ChatutilityPermissions from './models/ChatutilityPermissions';
import ChatutilityUsersService from './service/ChatutilityUsersService';
import ChatutilityRoleService from './service/ChatutilityRoleService';
import ChatutilityAddUserService from './service/ChatutilityAddUserService';
import ChatutilityEditUserService from './service/ChatutilityEditUserService';
import ChatutilityAddRolesService from './service/ChatutilityAddRolesService';
import ChatutilityPermissionsService from './service/ChatutilityPermissionService';
import ChatutilityUpdateRolesService from './service/ChatutilityUpdateRolesService';
import ChatUtilityGetTypesService from './service/ChatUtilityGetTypesService';
import PopularPagesService from './service/PopularPagesService';
import PopularPageModel from './models/PopularPage';
import ChatUtilityGetTypesModel from './models/ChatUtilityGetTypesModel';
import AIChartsUtilityService from './service/AIChartsUtilityService';
import AIChartsUtility from './models/AIChartsUtility';
import AIContentFilterChartsService from './service/AIContentFilterChartsService';
import AIContentFilterCharts from './models/AIContentFilterCharts';
import TrendingTopics from './models/TrendingTopics';
import TrendingTopicsService from './service/TrendingTopicsService';
import GetTrendingTopicsService from './service/GetTrendingTopicsService';
import TrendingTopicByBook from './models/TrendingTopicByBook';
// TODO: Hide Reports tab
// '/chatgptutility/aistudytools/reports',
// '/chatgptutility/channels/reports',

export default {
  path: [
    '/chatgptutility/aistudytools',
    '/chatgptutility/aistudytools/feedback',
    '/chatgptutility/aistudytools/trends',
    '/chatgptutility/aistudytools/trends/charts',
    '/chatgptutility/aistudytools/trends/clustering',
    '/chatgptutility/aistudytools/trends/unanswered',
    '/chatgptutility/aistudytools/mcq/view/:bookId',
    '/chatgptutility/aistudytools/mcq/view',
    '/chatgptutility/aistudytools/mcq/add/:bookId',
    '/chatgptutility/aistudytools/mcq/add',
    '/chatgptutility/aistudytools/mcq/edit',
    '/chatgptutility/aistudytools/mcq/edit/:bookId',
    '/chatgptutility/aistudytools/titlesearch',
    '/chatgptutility',
    '/chatgptutility/channels',
    '/chatgptutility/channels/feedback',
    '/chatgptutility/channels/trends',
    '/chatgptutility/admin',
    '/chatgptutility/aistudytools/summary/view/:bookId',
    '/chatgptutility/aistudytools/summary/view',
    '/chatgptutility/aistudytools/summary/add/:bookId',
    '/chatgptutility/aistudytools/summary/add',
    '/chatgptutility/aistudytools/summary/edit',
    '/chatgptutility/aistudytools/summary/edit/:bookId',
    '/chatgptutility/aistudytools/trends/effectiveness',
    '/chatgptutility/aistudytools/promptmgt',
    '/chatgptutility/channels/promptmgt',
    '/chatgptutility/tenants',
    '/chatgptutility/users',
    '/chatgptutility/roles',
    '/chatgptutility/popularpage',
    '/chatgptutility/pvsassessment',
    '/chatgptutility/pvsassessment/selectlesson',
    '/chatgptutility/pvsassessment/selectlesson/chapter',
    '/chatgptutility/chatbotconfig',
    '/chatgptutility/aicharts',
    '/chatgptutility/aicontentfiltercharts',
    '/chatgptutility/aitopiccharts',
    '/chatgptutility/aitrendingtopics',
    '/chatgptutility/*'
  ],
  exact: true,
  services: [
    new ChatgptUtilityService(),
    new ChatGptUtilityFeedbackService(),
    new ChatGptUtilityResponseService(),
    new ChatgptUtilityIESService(),
    new ChatGptUtilityTrendsService(),
    new ChatGptUtilityMappingService(),
    new ChatGptUtilitySubTypeService(),
    new ChatGptUtilityListingService(),
    new AssetService(),
    new ChatGptUtilityTocService(),
    new ChatGptUtilitySummaryService(),
    new ChatGptUtilitySummaryUpdateService(),
    new ChatGptUtilitySummaryStatusUpdateService(),
    new ChatGptUtilityMcqEditService(),
    new ChatGptUtilityTocAddService(),
    new McqStatusDeleteService(),
    new McqStatusUpdateService(),
    new McqUtilityBookSearchService(),
    new ChannelsUtilityService(),
    new ChannelsUtilityTrendsListingService(),
    new ChannelsUtilitytrendsService(),
    new ChannelsSubTypeService(),
    new ChannelsModelService(),
    new ChatgptAdminService(),
    new ChatGptGetAdminervice(),
    new ChatGptEditAdminService(),
    new ChatGptGetAdminPromptsService(),
    new ChatGptAddAdminPromptsService(),
    new ChatGptEditAdminPromptsService(),
    new StudyToolEffectivenessService(),
    new EffectivenessInitiateService(),
    new EffectivenessMetadataService(),
    new McqPublishAllStatusUpdateService(),
    new ChatGptPublishAllSummaryStatusUpdateService(),
    new CommonSearchUtilityService(),
    new ChatutilityUsersService(),
    new ChatutilityRoleService(),
    new ChatutilityAddUserService(),
    new ChatutilityEditUserService(),
    new ChatutilityAddRolesService(),
    new ChatutilityPermissionsService(),
    new ChatutilityUpdateRolesService(),
    new PopularPagesService(),
    new ChatUtilityGetTypesService(),
    new AIChartsUtilityService(),
    new AIContentFilterChartsService(),
    new TrendingTopicsService(),
    new GetTrendingTopicsService()
  ],
  stores: [
    {
      name: 'effectivenessData',
      spec: EffectivenessData,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'ChatgptUtilityIESUserData',
      spec: chatutilityIESData,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'chatgptUtility',
      spec: ChatgptUtility,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptFeedbackUtility',
      spec: Feedback,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptResponseUtility',
      spec: Response,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'ChatUtilitySubTypeResponse',
      spec: SubTypeResponse,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'chatgptTrendsUtility',
      spec: Trends,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptMappingUtility',
      spec: Mapping,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptListingUtility',
      spec: Listing,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'assetTOC',
      spec: AssetTOC,
      saveLocally: false,
      initialState: {
        id: '',
        title: '',
        children: [
          {
            id: '',
            title: '',
            type: '',
            assetType: '',
            uri: '',
            required: false
          }
        ]
      }
    },
    {
      name: 'chatgptTocUtility',
      spec: Toc,
      saveLocally: false,
      initialState: {
        chatgptUtility: {}
      }
    },
    {
      name: 'chatgptSummaryUtility',
      spec: Summary,
      saveLocally: false,
      initialState: {
        chatgptSummaryUtility: {}
      }
    },
    {
      name: 'mcqSearch',
      spec: McqSearch,
      saveLocally: false,
      initialState: {}
    },
    {
      name: 'channelsUtility',
      spec: ChannelsUtility,
      saveLocally: false,
      initialState: {
        channelsUtility: {}
      }
    },
    {
      name: 'channelsSubType',
      spec: ChannelsSubType,
      saveLocally: false,
      initialState: {
        channelsSubType: {}
      }
    },
    {
      name: 'channelsListingUtility',
      spec: ChannelsListing,
      saveLocally: false,
      initialState: {
        channelsUtility: {}
      }
    },
    {
      name: 'channelsTrendsUtility',
      spec: ChannelsTrends,
      saveLocally: false,
      initialState: {
        channelsUtility: {}
      }
    },
    {
      name: 'channelsModelData',
      spec: ChannelsModel,
      saveLocally: false,
      initialState: {
        channelsModelData: {}
      }
    },
    {
      name: 'chatgptAdmin',
      spec: ChatgptAdmin,
      saveLocally: false,
      initialState: {
        chatgptAdmin: {}
      }
    },
    {
      name: 'chatgptAdminPrompts',
      spec: ChatgptAdminPromptsData,
      saveLocally: false,
      initialState: {
        chatgptAdminPrompts: {}
      }
    },
    {
      name: 'commonSearchUtility',
      spec: CommonSearchModel,
      saveLocally: false,
      initialState: {
        commonSearchUtility: {}
      }
    },
    {
      name: 'chatutilityusersState',
      spec: ChatutilityUsers,
      saveLocally: false,
      initialState: {
        data: [],
        addUser: '',
        updateUser: ''
      }
    },
    {
      name: 'chatutilityuserRoles',
      spec: ChatutilityRoles,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'chatutilityrolesState',
      spec: ChatutilityRoles,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'chatutilityuserPermissions',
      spec: ChatutilityPermissions,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'popularPages',
      spec: PopularPageModel,
      saveLocally: false,
      initialState: {
        popularPages: {}
      }
    },
    {
      name: 'chatUtilityGetTypes',
      spec: ChatUtilityGetTypesModel,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'aiChartsUtility',
      spec: AIChartsUtility,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'aiContentfilterCharts',
      spec: AIContentFilterCharts,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'trendingTopics',
      spec: TrendingTopics,
      saveLocally: false,
      initialState: {
        data: []
      }
    },
    {
      name: 'trendingTopicByBook',
      spec: TrendingTopicByBook,
      saveLocally: false,
      initialState: {
        data: []
      }
    }
  ],
  render: (props) => (
    <ChatGptUtilityContainer {...props} /> // eslint-disable-line react/prop-types
  )
};
